import React from "react";
import { graphql, Link } from "gatsby";
import { mapRemarkToPage } from "../../helpers";
import { Layout, SimpleRollPage } from "../../components";
import { ArrowRight } from "../../components/SVG";
import { PressRelease, PageProps } from "../../models/";
import { PasswordProtectedPage } from "../../components/Press/PasswordProtectedPage";
import "../../styles/_page.scss";
import "../../styles/_press.scss";

const AllPressReleasesPage: React.FC<PageProps<PressRelease>> = ({
  data,
  location,
}) => {
  const posts = mapRemarkToPage<PressRelease[]>(
    (data as any).allMarkdownRemark.edges
  );

  return (
    <Layout>
      <PasswordProtectedPage master location={location}>
        <SimpleRollPage
          title="Press Releases Pages"
          noLayout
          wrapperClasses="min-height"
        >
          <ul>
            <li>
              <Link to="/press/" className="link">
                <span>
                  <ArrowRight
                    className="icon"
                    height="20"
                    width="20"
                    stroke={"#fff"}
                    style={{ transform: "rotate(-180deg)", marginRight: "1ch" }}
                  />
                  All Press Pages
                </span>
              </Link>
            </li>
            {!posts?.length ? (
              <div>
                <h2 className="mute">No Press Releases Pages to show</h2>
              </div>
            ) : (
              posts.map((post) => (
                <li key={post.slug}>
                  <Link to={post.slug} className="link">
                    <span>{post.title}</span>
                  </Link>
                </li>
              ))
            )}
          </ul>
        </SimpleRollPage>
      </PasswordProtectedPage>
    </Layout>
  );
};

export default AllPressReleasesPage;

export const pageQuery = graphql`
  query AllPressReleasesPages {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___created_at] }
      filter: { frontmatter: { templateKey: { eq: "press-release" } } }
    ) {
      edges {
        node {
          id
          html
          fields {
            slug
          }
          frontmatter {
            title
            created_at(formatString: "MMMM DD, YYYY")
            modified_at(formatString: "MMMM DD, YYYY")
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 250, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
