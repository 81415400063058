import React from "react";
import { AppActions, GlobalDispatch, GlobalState } from "../../store";
import { SectionWrapper } from "../Sections";
import { isVisited, validatePassword } from "./PressPassHelper";
import "../../components/Sections/Footer/Contact/Contact.scss";

type PasswordBased = {
  password: string;
  master?: boolean;
};
type MasterBased = {
  password?: string;
  master: boolean;
};

type PasswordProtectedPageProps = {
  children: React.ReactNode;
  location: Location;
  onCanEnter?: any;
} & (PasswordBased | MasterBased);

export const PasswordProtectedPage: React.FC<PasswordProtectedPageProps> = ({
  password,
  master,
  children,
  location,
  onCanEnter = () => true,
}: PasswordProtectedPageProps) => {
  const [passwordInput, setPasswordInput] = React.useState("");
  const [passwordError, setPasswordError] = React.useState(false);
  const state = React.useContext(GlobalState);
  const dispatch = React.useContext(GlobalDispatch);

  const hasVisited = React.useMemo(
    () => isVisited(state.visitedPages, location.pathname),
    [state, location]
  );

  const [canEnter, setCanEnter] = React.useState(
    (!password && !master) || hasVisited
  );

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordInput(e.target.value);
  };

  const handleSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (validatePassword(master ? null : password, passwordInput)) {
        setPasswordError(false);
        setCanEnter(true);
        dispatch(AppActions.addVisitedPage(location.pathname));
      } else {
        setPasswordError(true);
      }
    },
    [passwordInput, password, master, location, dispatch]
  );

  React.useEffect(() => {
    onCanEnter?.();
  }, [canEnter]);

  if (canEnter) {
    return <>{children}</>;
  }

  return (
    <main className="ppp">
      <SectionWrapper
        title="Password protected page"
        className="full min-height release__info__dsp  no-padding"
      >
        <div className="ppp__wrapper">
          <form onSubmit={handleSubmit} className="contact__form">
            <h2>If you're here, it must be for a reason</h2>
            <p>Unfortunately this page is protected by a password,</p>
            <div className="contact__field">
              <input
                maxLength={80}
                required={true}
                type={"text"}
                name="password"
                className="input"
                placeholder="Password"
                autoComplete="password"
                value={passwordInput}
                onChange={handlePasswordChange}
              />
            </div>
            <div className="contact__footer">
              <button type="submit">
                <span>Send</span>
              </button>
              {passwordError && (
                <p style={{ color: "red" }}>Incorrect Password</p>
              )}
            </div>
            <a href="#footer" className="mute" style={{ width: "fit-content" }}>
              dont have a password? contact
            </a>
          </form>
        </div>
      </SectionWrapper>
    </main>
  );
};

PasswordProtectedPage.displayName = "PasswordProtectedPage";
